/* ==========================================================================
Components
  > Article Listing
========================================================================== */

@mixin t-article-latest--medium {
  grid-row: span 3;

  .c-figure__hold {
    flex: 0 0 100%;
    margin-right: 0;
  }
}

.c-article__listing {
  list-style: none;
  margin: 0;
  &--main,
  &--sub {
    @include bp-large {
      grid-template-rows: 3fr 3fr;
      .c-article--sub &,
      .c-article--main & {
        &.c-article__listing {
          row-gap: 16px;
        }
      }
      .c-figure__hold {
        margin-right: var(--gutter);
        flex: 0 0 33.3333%;
      }

      .c-article--grid {
        display: flex;

        figure {
          margin-bottom: 0;
        }
      }

      // In a sidebar page
      .c-article--main.col.l8 & {
        grid-template-rows: 1fr;
        row-gap: var(--gutter);

        li {
          grid-column: span 2;
        }
      }
    }
  }
  &--main {
    li {
      &:first-child {
        @include bp-small-medium {
          grid-column: span 2;
        }
        @include bp-large {
          grid-row: span 3;
          margin-bottom: 48px;
          .c-figure__hold {
            flex: 0 0 calc(66.6666666667% + 9px);

            .c-article--main.l8 & {
              margin-right: 0;
            }
          }

          .c-article--grid {
            .c-article--main.l8 & {
              flex-direction: column;
            }
          }
        }
      }
      &:nth-child(2) {
        @include bp-large {
          grid-row: span 3;
          .c-figure__hold {
            flex: 0 0 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
  &--sub {
    li {
      &:first-child {
        @include bp-large {
          // Not in a sidebar page
          .c-article--main.col:not(.l8) & {
            @include t-article-latest--medium;
          }
          // In a sidebar page
          .c-article--main.col.l8 & {
            grid-column: span 2;
            grid-row: span 1;

            .c-figure__hold {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

// In main article

.c-article--main {
  .c-article__listing {
    display: grid;
    gap: 40px 0;
    grid-template-columns: 1fr; // All 1 col without [data att]

    // 2 cols

    @include bp-small-medium {
      grid-template-columns: 1fr 1fr; // All 2 col without [data att]
      gap: 48px var(--gutter);
    }

    // Edge Case: For 3 col listings that return only 3 results, make first listing is full width within these widths

    @media (min-width: 540px) and (max-width: 959px) {
      &[data-listing__cols='3'][data-listing__count='3'] {
        li:first-child {
          grid-area: 1/1/2/3;
        }
      }
    }

    // 3 col

    @include bp-medium-large {
      &[data-listing__cols='3'] {
        grid-template-columns: 1fr 1fr 1fr;
      }

      // 4 col

      &[data-listing__cols='4'] {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      // Edge Case: For 4 col listings that return only 4 results, make listing 2 cols @ml

      // @include bp-max-xlarge {
      //   &[data-listing__cols='4'][data-listing__count='4'] {
      //     grid-template-columns: 1fr 1fr;
      //   }
      // }
    }

    // 4 col listing

    @include bp-xlarge {
      &[data-listing__cols='4'] {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    // 2 cols - 8 / 4 (Featured)

    &.c-article__listing--featured {
      @include bp-small-medium {
        .c-article__listing__featured {
          grid-area: 1 / 1 / 2 / 3;
        }
      }

      @include bp-medium-large {
        row-gap: var(--gutter);
        grid-template: 1fr 1fr / 67.41214057507987fr 32.58785942492013fr;

        .c-article__listing__featured {
          grid-area: 1 / 1 / 3 / 2;
        }

        article,
        figure {
          height: 100%;
        }

        .c-link--cover:hover + figure img {
          opacity: 0.48;
        }

        .u-aspect {
          margin: 0;
          padding-bottom: 0;
          background-color: var(--color--primary--dark);

          img {
            position: relative;
            transition: var(--global__transition);
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(
              to bottom,
              rgba(7, 15, 24, 0) 0%,
              rgba(7, 15, 24, 0.66) 100%
            );
            width: 100%;
            height: 50%;
          }
        }

        .c-article__copy {
          position: absolute;
          bottom: 24px;
          left: 24px;
        }
      }
    }

    h2 {
      margin-bottom: 8px;
    }

    // Vertical - e.g. news-listing.html

    &[data-listing__cols='1'] {
      .c-figure__hold {
        flex: 0 0 auto;
        max-width: 100%;
      }

      .c-article__copy {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
      }

      @include bp-small-medium {
        .c-figure__hold {
          margin-right: var(--gutter);
          width: 50%;
          max-width: 300px;

          @include bp-medium {
            width: 300px;
          }
        }

        .c-article--grid {
          display: flex;

          figure {
            margin-bottom: 0;
          }
        }
      }

      @include bp-small-medium {
        grid-template-columns: 1fr !important;
      }
    }
  }

  // Sidebar

  &.l8 {
    .c-article__listing {
      &[data-listing__cols='3'] {
        @include bp-large {
          grid-template-columns: 1fr 1fr;
        }

        @include bp-xlarge {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }

      &[data-listing__cols='4'] {
        @include bp-large {
          grid-template-columns: 1fr 1fr;
        }

        @include bp-xlarge {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
}

// Grid

.c-article--grid {
  margin-bottom: 0;

  figure {
    margin-bottom: 20px;
  }

  img {
    border-radius: var(--global__radius);
    margin-bottom: 0;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin-bottom: 10px;
  }

  .h5 {
    margin-bottom: 8px;
  }

  p {
    margin: 0;
  }

  a,
  p,
  .c-list--meta {
    + p {
      margin-top: 12px;
    }
  }

  .fal {
    width: 24px;
    text-align: center;
  }
}

// Divided e.g. Search Result / Recent Articles

.c-article--divided {
  &:not(:last-child) {
    margin-bottom: var(--gutter);
    padding-bottom: var(--gutter);
    border-bottom: 1px solid var(--color--grey--light);
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;

    .c-btn--download {
      margin-bottom: 0;
    }
  }
}
